import { useState, useEffect } from 'react';
import { Button, Col, Row, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSessionStore } from "../../../../../Stores/SessionStore";
import { useFormContext } from 'react-hook-form';
import { Roles } from '../../../../../Utilities/Enums';
import FormSection from "../../../../Core/Forms/FormSection";
import InputField from "../../../../Core/Forms/InputField";
import DateField from '../../../../Core/Forms/DateField';
import CheckboxField from "../../../../Core/Forms/CheckboxField";
import UserRoleSelect from "../../../Common/Components/UserRoleSelect";

const AssignToTeamLeader = ({ dateScheduled, value, disabled, setModal, isSubmitted }) => {
    const { getValues } = useFormContext();
	const store = useSessionStore();
    const navigate = useNavigate();
    const isAdmin = store.Session.Role === Roles.Admin;

    const submitToTeamLeader = async () => {

        const data = getValues();

        let result = await store.SalesQuotesService.SubmitSalesQuoteToTeamLeader(data.Id, data.TeamLeaderId);

        if (result?.Success) {
            setModal({
                show: true,
                header: "Success!",
                message: "Estimate submitted to Team Leader successfully",
                onClose: () => {setModal({show: false, header: "", message: ""}); navigate(-1)}
            });
        } else {
            setModal({
                show: true,
                header: "Error!",
                message: result.Message,
                onClose: () => {setModal({show: false, header: "", message: ""})}
            });
        }
    }

    const UnSubmitSalesQuote = async () => {

        let result = await store.SalesQuotesService.UnSubmitSalesQuote(getValues("Id"));

        if (result?.Success) {
            setModal({
                show: true,
                header: "Success!",
                message: "Estimate successfully opened for editing",
                onClose: () => {setModal({show: false, header: "", message: ""}); navigate(-1)}
            });
        } else {
            setModal({
                show: true,
                header: "Error!",
                message: result.Message,
                onClose: () => {setModal({show: false, header: "", message: ""})}
            });
        }
    }

    return (
        <FormSection title="">
            <Row>
                <Col>
                    <p className="fw-bold">Admin Use Only after submission</p>
                </Col>
            </Row>
            <Stack gap={3}>
                <Row>
                    <Col className="col-12 col-md-6 mb-3 mb-md-0">
                        <Stack gap={3}>
                            <UserRoleSelect label="Assign To Team Leader" name="TeamLeaderId" value={value} disabled={disabled || (isSubmitted && !isAdmin)} role={Roles.TeamLeader}/>
                            <Col className="col-12 col-md-6">
                                <Button className="w-100 d-print-none" variant="secondary" onClick={submitToTeamLeader} disabled={disabled || (isSubmitted && !isAdmin)}>{isSubmitted ? isAdmin ? "Reassign Team Leader" : "Assigned" : "Submit to Team Leader"}</Button>
                            </Col>
                        </Stack>
                    </Col>
                    <Col className="col-12 col-md-6">
                        <Stack gap={3}>
                            <InputField label="TTL Day/Hours Taken" name="HoursTaken" disabled={disabled} />
                            <DateField label="Date Scheduled" name="DateScheduled" value={dateScheduled} disabled={disabled}/>
                        </Stack>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-6 col-md-3 mb-3 mb-md-0">
                        <CheckboxField label="Estimate Authorised (Xero)" name="EstimateAccepted" disabled={disabled}/>
                    </Col>
                    <Col className="col-6 col-md-3 mb-3 mb-md-0">
                        <CheckboxField label="Deposit Received" name="DepositReceived" disabled={disabled}/>
                    </Col>
                    <Col  className="col-6 col-md-3">
                        <CheckboxField label="Job Lost" name="JobLost" disabled={disabled}/>
                    </Col>
                    <Col className="col-6 col-md-3">
                        <CheckboxField label="Job Complete" name="JobCompleted" disabled={disabled}/>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-6 col-md-3">
                        <CheckboxField label="Invoiced" name="Invoiced" disabled={disabled}/>
                    </Col>
                    <Col className="col-6 col-md-3">
                        <CheckboxField label="Job Accepted" name="JobAccepted" disabled={disabled}/>
                    </Col>
                    <Col className="col-6 col-md-3">
                        {isAdmin && <Button className="w-100 d-print-none" variant="secondary" disabled={ !isSubmitted } onClick={UnSubmitSalesQuote}>Open for editing</Button>}
                    </Col>
                </Row>
            </Stack>
        </FormSection>
    )
}

export default AssignToTeamLeader;