import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import SearchField from "../../../../Core/Forms/SearchField";
import TeamLeaderFilter from "../../../Common/Components/TeamLeaderFilter";

/**
 * @param {Object} HeaderBarProps 
 * @param {(search: string, status: number) => void} HeaderBarProps.onFilterClicked 
 */
export default function Headerbar({ onFilterClicked, onTeamLeaderSelected }) {

	const [search, setSearch] = useState("");
	const [status, setStatus] = useState("");

	return 	<Row>
				<Col className="col-12 col-lg-4 mb-3 mb-lg-0">
					<SearchField label="Search" name="search" value={search} onChange={e => setSearch(e.target.value)} onClick={e => onFilterClicked(search, status)} disabled={false} />
				</Col>
				<Col className="col-12 col-lg-3 mb-3 mb-lg-0">
					<Form.Select placeholder="Status" value={status} onChange={ e => {setStatus(e.target.value); onFilterClicked(search, e.target.value);} }>
						<option value="">Select a Status...</option>
						<option value="1">Estimate in Progress</option>
						<option value="2">Authorisation Pending</option>
						<option value="3">Estimate Authorised</option>
						<option value="4">Deposit Received</option>
						<option value="5">Invoiced</option>
						<option value="6">Job Completed</option>
						<option value="7">Job Accepted</option>
						<option value="8">Job Lost</option>
					</Form.Select>
				</Col>
				<Col className="col-12 col-lg-3 mb-3 mb-lg-0">
					<TeamLeaderFilter status={status} onTeamLeaderSelected={onTeamLeaderSelected} />
				</Col>
				<Col className="col-12 col-lg-3 col-xl-2 col-xxl-2">
					<a href="#/SalesQuotes/Add" className="btn btn-secondary w-100">Create Estimate</a>
				</Col>
			</Row>
}