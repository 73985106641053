import { useState, useEffect, useRef } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useSessionStore } from "../../../../../Stores/SessionStore";
import { Roles } from "../../../../../Utilities/Enums";
import SalesQuoteValidationSchema from "../Validation/SalesQuoteValidationSchema";
import PageContainer from "../../../../Core/PageContainer";
import CompletedBySection from "../../../../Core/Forms/CompletedBySection";
import FormContainer from "../../../../Core/Forms/FormContainer";
import Photos from "../../../Common/Components/Photos";
import MessageModal from "../../../../Core/Forms/MessageModal";
import CustomerDetails from "../../../Common/Components/CustomerDetails";
import SiteDetails from "../../../Common/Components/SiteDetails";
import AssignToTeamLeader from "./AssignToTeamLeader";
import SalesQuoteDetails from "./SalesQuoteDetails";
import JobProductDetails from "./JobProductDetails";
import JobDescriptions from "./JobDescriptions";
import Quote from "./Quote";
import JobRequirements from "../../../Common/Components/JobRequirements";

export default function SalesQuote() {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
	const store = useSessionStore();
    const createQuote = location?.pathname === "/SalesQuotes/Add" ? true : false;
    const printElement = useRef(null);

    const [viewOnly, setViewOnly] = useState(location.state?.action && location.state.action === "view" ? true : false);
    const [quote, setQuote] = useState(null);
    const [modal, setModal] = useState({show: false, header: "", message: ""});
    const [submitEnabled, setSubmitEnabled] = useState(false);

    const form = useForm({mode: "onChange", resolver: yupResolver(SalesQuoteValidationSchema)});
    const { handleSubmit, formState, getValues } = form;

    const adminDisabled = store.Session.Role === Roles.Admin ? false : true;

    useEffect(() => {
        if (params.id) {

            getSaleQuote(params.id);
        } else if (createQuote) {

            setQuote({...Quote, JobRequirements: []});
        }
    }, []);

    useEffect(() => {
            if (quote) form.reset(quote, {keepDirtyValues: true});            
    }, [quote]);

    useEffect(() => {

        if (Object.keys(formState.errors).length === 0) {
            setSubmitEnabled(true);
        } else {
            setSubmitEnabled(false);
        }
    }, [formState]);

    const getSaleQuote = async (id) => {

        let result = await store.SalesQuotesService.GetSalesQuote(id);

		if(result && result.Data) {

            let formData = {...result.Data};
            
            if (formData.SubmittedToAdminOn && store.Session.Role !== Roles.Admin) {
                setViewOnly(true);
            }

            for (let i = 0; i < formData.JobDescriptions.length; i++) {
                formData.JobDescriptions[i].LineTotal = 0;
            }

            formData.JobDescriptions.sort((a, b) => a.TaskOrder - b.TaskOrder);

            formData.SalesQuoteSite.TotalProductionCost = formData.SalesQuoteSite.TotalProductionCost + "";

            setQuote(formData);
		}
    }

    const onSubmit = async () => {

        let salesQuoteId = params.id ? params.id : null;

        if (!params.id) {
            salesQuoteId = await onSave(true);
        } else {
            await onSave(true);
        }

        if (!salesQuoteId) return;

        let result = await store.SalesQuotesService.SubmitSalesQuote(salesQuoteId);

        if (result?.Success) {
            setModal({
                show: true,
                header: "Success!",
                message: "Estimate submitted successfully",
                onClose: () => {onSuccess()}
            });
        } else {
            setModal({
                show: true,
                header: "Error!",
                message: result.Message,
                onClose: () => {setModal({show: false, header: "", message: ""})}
            });
        }
    }

    const onError = (errors, e) => console.log(errors, e);

    const onSave = async (submit = false) => {

        const data = getValues();
        let errorMessage = "";

        const { 
            SalesRepId,
            LeadFrom,
            Customer,
            CustomerBrief,
            SpecialMachineryRequired,
            AdditionalInformation,
            Site,
            SalesQuoteSite,
            JobRequirements,
            JobDescriptions,
            JobProductDetails,
            Photos,
            SubmittedToTeamLeaderOn,
            SubmittedToTeamLeaderById,
            SubmittedToAdminOn,
            TeamLeaderId,
            Date
        } = data;

        if (!SalesRepId) errorMessage = "Please select a Sales Rep before saving.";
        if (!Date) errorMessage = "Please select a Date before saving.";

        if (errorMessage) {
            
            setModal({
                show: true,
                header: "Error!",
                message: errorMessage,
                onClose: () => {setModal({show: false, header: "", message: ""})}
            });
            return;
        }
        
        let result = null;

        let newPhotos = [...Photos].flat().filter(photo => photo.Base64Data !== "");

        let newJobDescriptions = JobDescriptions.map(jobDescription => {

            let unitCost = jobDescription.UnitCost + "";

            unitCost = unitCost.replaceAll(",", "");

            return {
                ...jobDescription,
                Squares: jobDescription.Squares !== "" ? jobDescription.Squares : null,
                LinealMeters: jobDescription.LinealMeters !== "" ? jobDescription.LinealMeters : null,
                UnitCost: unitCost !== "" ? unitCost : "0"
            }
        });

        let salesQuote = {
            JobRequirements,
            JobDescriptions: newJobDescriptions,
            JobProductDetails,
            Photos: newPhotos,
            Date,
            SalesQuoteSite,
            Customer,
            CustomerBrief,
            LeadFrom,
            SalesRepId,
            Site,
            SpecialMachineryRequired,
            AdditionalInformation,
            SubmittedToTeamLeaderOn,
            SubmittedToTeamLeaderById,
            SubmittedToAdminOn,
            TeamLeaderId,
            JobSheetId: quote.JobSheetId
        }

        if (createQuote) {
            
            result = await store.SalesQuotesService.CreateSalesQuote(salesQuote);

        } else {

            salesQuote = {
                ...salesQuote,
                Id: data.Id,
                EstimateAccepted: data.EstimateAccepted,
                DateScheduled: data.DateScheduled.substring(0, 4) === "0000" ? "0001-01-01" : data.DateScheduled,
                DepositReceived: data.DepositReceived,
                Invoiced: data.Invoiced,
                JobCompleted: data.JobCompleted,
                JobLost: data.JobLost,
                JobAccepted: data.JobAccepted,
                SalesRepId: data.SalesRepId,
                HoursTaken: data.HoursTaken,
                SalesQuoteNumber: quote.SalesQuoteNumber
            }
            
            result = await store.SalesQuotesService.UpdateSalesQuote(salesQuote);            
        }

        if (!submit) {
            if (result?.Success) {

                setModal({
                    show: true,
                    header: "Success!",
                    message: "Estimate saved successfully",
                    onClose: () => {onSuccess()}
                });
            } else {
                setModal({
                    show: true,
                    header: "Error!",
                    message: "An error occurred while saving the Estimate",
                    onClose: () => {setModal({show: false, header: "", message: ""})}
                });
            }
        }

        return result?.Data;
    }

    const onSuccess = () => {
        setModal({show: false, header: "", message: ""});
        navigate(-1);
    }

	return 	<PageContainer title="Projects" subtitle="Estimates">
                {quote ?
                    <FormContainer formTitle="Estimate" formNumber={quote && quote.SalesQuoteNumber && `EST${quote.SalesQuoteNumber}`} viewOnly={viewOnly}>
                        <FormProvider { ...form } >
                            <Form className="text-black" onSubmit={handleSubmit(onSubmit, onError)} ref={printElement}>
                                <AssignToTeamLeader value={quote.TeamLeaderId} disabled={adminDisabled || viewOnly || !quote.SubmittedToAdminOn} setModal={setModal} dateScheduled={quote && quote.DateScheduled} isSubmitted={quote.SubmittedToTeamLeaderOn}/>
                                <SalesQuoteDetails disabled={viewOnly} date={quote && quote.Date} salesRep={quote && quote.SalesRepId}/>
                                <CustomerDetails disabled={viewOnly}/>
                                <SiteDetails disabled={viewOnly} quote />
                                <JobRequirements disabled={viewOnly} requirements={quote && quote.JobRequirements}/>
                                <JobDescriptions
                                    isSalesQuote
                                    disabled={viewOnly}
                                />
                                <JobProductDetails
                                    disabled={viewOnly}
                                />
                                <Photos                                
                                    disabled={viewOnly}
                                />
                                <CompletedBySection submittedToAdminOn={quote?.SubmittedToAdminOn ? quote.SubmittedToAdminOn : ""} disabled={viewOnly} onSave={onSave} submitEnabled={submitEnabled}/>
                            </Form>
                        </FormProvider>
                    </FormContainer> :
                    <div className="text-center pt-5 mt-5">
                        <Spinner variant="light"></Spinner>
                    </div>
                }
                <MessageModal modal={modal}/>
			</PageContainer>
}